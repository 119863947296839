/* eslint-disable */

/*
| ----------------------------------------------------------------------------------
| TABLE OF CONTENT
| ----------------------------------------------------------------------------------
-Settings
-Dropdown Menu Fade
-Chars Start
-Isotope filter
-Accordion
-Sliders
-Animated WOW
*/
$(document).ready(function () {
  "use strict";

  /////////////////////////////////////////////////////////////////
  // SETTING
  /////////////////////////////////////////////////////////////////

  var windowHeight = $(window).height();
  var windowWidth = $(window).width();

  var tabletWidth = 767;
  var mobileWidth = 640;

  /////////////////////////////////////////////////////////////////
  //   Dropdown Menu Fade
  /////////////////////////////////////////////////////////////////

  $(".dropdown").hover(
    function () {
      $(".dropdown-menu", this).stop(true, true).slideDown("fast");
      $(this).toggleClass("open");
    },
    function () {
      $(".dropdown-menu", this).stop(true, true).slideUp("fast");
      $(this).toggleClass("open");
    }
  );

  $(".yamm .navbar-nav>li").hover(
    function () {
      $(".dropdown-menu", this).fadeIn("fast");
    },
    function () {
      $(".dropdown-menu", this).fadeOut("fast");
    }
  );

  window.prettyPrint && prettyPrint();
  $(document).on("click", ".yamm .dropdown-menu", function (e) {
    e.stopPropagation();
  });

  /////////////////////////////////////////////////////////////////
  // Accordion
  /////////////////////////////////////////////////////////////////

  $(".btn-collapse").click(function () {
    $(".panel").removeClass("panel-default");
    $(this).parents(".panel").addClass("panel-default");
  });
});