import 'font-awesome/css/font-awesome.css'
import '../fonts/lineicons3/icon-font/scss/lineicons.scss'
import '../fonts/flaticon/flaticon.css'

import './theme-2/bootstrap.css'
// import './theme-2/isotope.css'
// import './theme-2/validation-2.2.css'
// import './theme-2/owl.carousel.css'
// import './theme-2/owl.transitions.css'
// import './theme-2/owl.theme.css'
// import './theme-2/prettyPhoto.css'
import './theme-2/yamm.css'
// import './theme-2/loader.css'
// import './theme-2/jquery.datetimepicker.css'
import './theme-2/animate.css'

import './theme-2/normalize.css'
import './theme-2/color.css'
import './theme-2/blog.css'
import './theme-2/theme.css'
import './theme-2/responsive.css'

import './theme-2.scss'

window.$ = window.jQuery = require('../vendor/jquery/dist/jquery')
require('../vendor/bootstrap/dist/js/bootstrap')